$primary:       #dc6b2f;// #e36622;
$secondary:     #75787b;// #797979;
$success:       #77c5d5;// #7bc2d2;
$info:          #bbdde6;// #c3e5ed;
//$warning:       #fbf17b;
//$danger:        #d21515;
$light:         #dbe2e9;// #dadde2;
$dark:          #373a36;// #3b3c3d;

//$body-bg: $light;

//$enable-shadows:            true;
//$enable-gradients:          true;
$enable-rounded: false;
$link-decoration: none;

$color-mode-type: media-query;
$code-color: $primary;
$code-color-dark: $primary;
$link-color-dark: $primary;
$body-color: darken($dark, 10%);
$body-bg: lighten($light, 10%);
$body-color-dark: lighten($light, 10%);
$body-bg-dark: darken($dark, 10%);

$border-width: 0px;

$spacer:                      2em;
$spacers: (
  0: 0,
  1: $spacer * .25,
  2: $spacer * .5,
  3: $spacer,
  4: $spacer * 1.5,
  5: $spacer * 3.5,
  6: $spacer * 4.5,
);

$font-size-base:              1.1rem; // Assumes the browser default, typically `16px`
$font-family-sans-serif:      "Poppins Light", sans-serif;
$font-family-monospace:       "Azeret Mono";

$nav-link-font-size:          $font-size-base * 0.80;
$navbar-toggler-focus-width:  0px;

$h1-font-size:                $font-size-base * 2;
$h2-font-size:                $font-size-base * 2;
$h3-font-size:                $font-size-base * 1.75;
$h4-font-size:                $font-size-base * 1.5;
$h5-font-size:                $font-size-base * 1.25;
$h6-font-size:                $font-size-base;

@font-face {
    font-family: 'Poppins Light';
    src: url('../fonts/FontsFree-Net-Poppins-Light.ttf');
    font-style: normal;
}

@font-face {
    font-family: 'Azeret Mono';
    src: url('../fonts/AzeretMono-ExtraLight.ttf');
    font-style: normal;
}

h1 {
    text-transform: uppercase;
}
h2 {
    text-transform: uppercase;
}

@import "lib/bootstrap/scss/bootstrap";
@include media-breakpoint-down(md) {
    div.employeeAvatars object {
      max-height: 9rem;
    }
}
@include media-breakpoint-down(sm) {
    div.employeeAvatars object {
      max-height: 5rem;
    }
}

hr {
    border-top: 2px solid;
    opacity: 1;
    color: $secondary;
}

ul {
    list-style-type: none;
}

#logo {
  min-height: 70px;
  width: 100%;
  min-width: 225px;
}
.nav-item {
    text-transform: uppercase;
}
.nav-item.active .nav-link:after {
    content: "";
    background-color: rgba(var(--bs-emphasis-color-rgb), 0.65);
    float: left;
    width: 100%;
    height: 2px;
    border-radius: 0px;
}

.lang-select {
  text-align: right;
  padding-right: $nav-link-padding-y;
  label {
    cursor: pointer;
  }
}
input#lang-da:checked ~ nav .lang-select label[for=lang-da]:after, input#lang-en:checked ~ nav .lang-select label[for=lang-en]:after {
    content: "";
    background-color: rgba(var(--bs-emphasis-color-rgb), 0.65);
    float: left;
    width: 100%;
    height: 2px;
    border-radius: 0px;
}

@include media-breakpoint-up(md) {
    .lang-select {
      position: absolute;
      top: $nav-link-padding-y;
      right: $nav-link-padding-y;
    }
}

html {
    visibility: visible;
    opacity: 1;
}

i.icon {
  display: inline-block;
  position: relative;
  top: 0.2em;
  width: 1em;
  height: 1em;
  &.mitid {
    width: 3em;
    background-image: url("../icons/mitid.svg")
  }
  &.mitid-light {
    width: 3em;
    background-image: url("../icons/mitid-light.svg")
  }
  &.mitid-blue {
    width: 3em;
    background-image: url("../icons/mitid-blue.svg")
  }
  &.mitid-white {
    width: 3em;
    background-image: url("../icons/mitid-white.svg")
  }
}

span.svg-icon {
  display: inline-block;
  width: 1em;

  &.arrow-left, &.arrow-right, &.arrow-up, &.arrow-down, &.cross-circle {
    font-size: 1.2em;
  }
  &.cross-orange {
    color: $primary;
  }
  &.mitid, &.mitid-light, &.mitid-white, &.mitid-blue {
    width: 3em;
  }
  &.mitid-light {
    color: $light;
  }
}

@include media-breakpoint-down(md) {
    #toggle-nav:not(:checked) ~ div.navbar-collapse, #toggle-nav:not(:checked) ~ div.navbar-collapse * {
        font-size: 0;
        margin: 0 !important;
        opacity: 0;
        padding: 0;
        transition: opacity .2s,
                    font-size .4s .2s,
                    margin .4s .2s,
                    padding .4s .2s;
    }

    #toggle-nav:checked ~ div.navbar-collapse, #toggle-nav:checked ~ div.navbar-collapse * {
        transition: font-size .2s,
                    margin .2s,
                    padding .2s,
                    opacity .4s .2s;
    }
}

label.navbar-toggler {
  cursor: pointer;
}

p.caption:empty {
    display: none;
}

div.employeeAvatars {
  min-width: 300px;
}

div.map-object div.figure {
    overflow: hidden;
}

@include media-breakpoint-up(xs) {
div.map-object object {
  transform: scale(2)
}
}

@include media-breakpoint-up(sm) {
div.map-object object {
  transform: scale(2)
}
}
@include media-breakpoint-up(md) {
div.map-object object {
  transform: scale(2)
}
}
@include media-breakpoint-up(lg) {
div.map-object object {
  transform: scale(1.2)
}
}

div.map-object div.figure {
  background-color: #77c5d5;
  border: .5rem solid #77c5d5;
}
div.map-object object {
  border: .5rem solid #77c5d5;
  transition: transform 1s;
}

.text-light p, p.text-light {
  text-shadow: 0 0 var(--bs-light);

  & a {
    text-shadow: none;
  }
}

.encrypted {
  position: relative;
  word-wrap: break-word;
  &.large:after {
    content: "";
    position: absolute;
    top: 8em;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: url(../images/chain_and_lock.svg);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: top;
    pointer-events: none;
  }
}

h1 + h6 {
  margin-top: -1.2em;
  color: $primary;
  text-transform: uppercase;
  font-size: .8em;
}

h1,h2,h3,h4,h5,h6 {
  & code {
    font-size: 1em;
  }
}

p+h1, p+h2, p+h3, p+h4, p+h5, p+h6, 
p+div>h1:first-child, p+div>h2:first-child, p+div>h3:first-child, p+div>h4:first-child, p+div>h5:first-child, p+div>h6:first-child
 , p+div>div>h1:first-child, p+div>div>h2:first-child, p+div>div>h3:first-child, p+div>div>h4:first-child, p+div>div>h5:first-child, p+div>div>h6:first-child{
  margin-top: $headings-margin-bottom;
}

.collapsable {
    position: relative;
    .collapsable-toggle {
      cursor: pointer;
      .svg-icon {
        font-size: 1em;
      }
    }
  .collapsable-header > *:first-child {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid $dark;
    label.collapse-icon {
      padding: .1em
    }
  }

  input[type=checkbox] {
    display: none;
  }

  input[type=checkbox]:not(:checked) ~ * .collapsable-toggle {
      svg {
        transition: .3s transform;
        transform: rotate(-45deg);
      }
      &:hover {
        svg {
          transform: rotate(45deg);
        }
      }
    }

  input[type=checkbox]:checked ~ * .collapsable-toggle {
      svg {
        transition: .3s transform;
        transform: rotate(0deg);
      }
    }
  input[type=checkbox]:not(:checked) ~ .collapsable-body * {
    font-size: 0 !important;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    opacity: 0 !important;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    transition: opacity .2s, font-size .2s .1s, margin-top .2s .2s, margin-bottom .2s .2s, padding-top .2s .2s, padding-bottom .2s .2s;
  }

  input[type=checkbox]:checked ~ .collapsable-body * {
    transition: font-size .1s, margin-top .2s, margin-bottom .2s, padding-top .2s, padding-bottom .2s, opacity .2s .4s;
  }
}

input#lang-da:not(:checked) ~ * *[lang=da] {
  display: none !important;
}

input#lang-en:not(:checked) ~ * *[lang=en] {
  display: none !important;
}

object {
  color-scheme: auto;
}